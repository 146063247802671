.contact{

}

.contact .contact-form{
    
}

.contact .contact-form h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
}

.contact .contact-form h1{
    font-size: 60px;
    font-weight: 800;
    color: #000;
}


.contact .contact-form p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}

.contact .contact-form form{
    margin-top: 50px;
    width: 100%;
}

.contact .contact-form form .inputs{
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.contact .contact-form form .inputs .input{
    width: 100%;
}

.contact .contact-form form .inputs .input input{
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 43%);
    padding-left: 15px;
}

.contact .contact-form form .inputs .input input::placeholder{
    color: #000;
}

.contact .contact-form form .inputs .input textarea{
    width: 100%;
    height: 165px;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 43%);
    padding-left: 15px;
    padding-top: 10px;
}

.contact .contact-form form .inputs .input textarea::placeholder{
    color: #000;
}


.contact .contact-form form button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 150px;
    border-radius: 6px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    color: #fff;
    outline: none;
    border: none;
}

.contact .contact-info{
    
}

.contact .contact-info iframe{
    border-radius: 15px;
}

.contact .contact-info h1{
    margin-top: 59px;
    font-weight: 700;
    color: #000;
    font-size: 34px;
}


.contact .contact-info p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}


.contact .contact-info .info{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.contact .contact-info .info i{
    width: 60px;
    height: 60px;
    border-radius: 15px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #fff;
}

.contact .contact-info .info div{
    width: 80%;
}

.contact .contact-info .info div h2{
    font-size: 22px;
    font-weight: 500;
    color: #000;
}

.contact .contact-info .info div p{
    margin: 0;
    width: 50%;
}


@media(max-width:950px){
    .contact .contact-form h1 {
        font-size: 40px;
        font-weight: 800;
        color: #000;
    }
}