
nav .upper-nav .content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #00000029;
}

nav .upper-nav .content .left{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

nav .upper-nav .content .left a{ 
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

nav .upper-nav .content .left span{ 
    height: 18px;
    width: 1px;
    background-color: #0000002e;
}

nav .upper-nav .content .right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

nav .upper-nav .content .right a{
    font-size: 15px;
    font-weight: 500;
    color: #000;
    display: flex;
    flex-direction: row;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

nav .upper-nav .content .right a i{
    font-size: 18px;
    color: #bba93c;
}

nav .upper-nav .content .right span{
    height: 18px;
    width: 1px;
    background-color: #0000002e;
}

nav .lower-nav{
    
}

nav .lower-nav .content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 93px;
}


nav .lower-nav .content .logo img{
    width: 124px;
}


nav .lower-nav .content .nav-elements ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}



nav .lower-nav .content .nav-elements ul li a{
    color: #000;
    transition: 0.5s;
}

nav .lower-nav .content .nav-elements ul li a.active{
    color: #b9ad44;
}

nav .lower-nav .content .right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}


nav .lower-nav .content .right a:nth-child(1){
    font-size: 25px;
    color: #000;
}

nav .lower-nav .content .right a:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 150px;
    border-radius: 6px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    color: #fff;
}

nav .lower-nav .content .right a:nth-child(3){
    font-size: 25px;
    color: #000;
}






#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #000;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .lower-nav .content .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 15015;
    }

    nav .lower-nav .content .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

    }

    
    nav .lower-nav .content .nav-elements{
        display: none;
    }

    nav .lower-nav .content .nav-elements ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }


    nav .lower-nav .content .nav-elements ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
        justify-content: center;
    }



    nav .upper-nav .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 74px;
        border-bottom: 1px solid #00000029;
        padding-top: 15px;
        padding-bottom: 15px;
    }



    nav .lower-nav .content .right {
        display: none;
        flex-direction: row;
        align-items: center;
        gap: 50px;
    }
}