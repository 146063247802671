.stats{
    margin-top: 90px;
    margin-bottom: 90px;
}


.stats .text h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
}


.stats .text h1{
    font-size: 50px;
    font-weight: 800;
    color: #000;
}

.stats .text h1 span{
    color: #b6ab38;
}


.stats .text p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}


.stats .text .stats-bars{
    margin-top: 36px;
    margin-bottom: 30px;
}


.stats .text .stats-bars .stat-bar{
    margin-bottom: 26px;
}

.stats .text .stats-bars .stat-bar h1{
    font-size: 20px;
    font-weight: 600;
}

.stats .text .stats-bars .stat-bar .bar{
    width: 90%;
    height: 20px;
    background-color: #b6ab3836;
    border-radius: 50px;
}



.stats-bars .stat-bar:nth-child(1) .bar-content {
    width: 98% !important;
}


.stats-bars .stat-bar:nth-child(2) .bar-content{
    width: 95% !important;
}

.stats-bars .stat-bar:nth-child(3) .bar-content{
    width: 90% !important;
}

.stats-bars .stat-bar:nth-child(1) .bar-content span{
    left: 98%;
}

.stats-bars .stat-bar:nth-child(2) .bar-content span{
    left: 95%;
}

.stats-bars .stat-bar:nth-child(3) .bar-content span{
    left: 90%;
}


.stats .text .stats-bars .stat-bar .bar .bar-content{
    width: 0;
    height: 20px;
    background-color: #b6ab3836;
    border-radius: 50px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    position: relative;
}


.stats .text .stats-bars .stat-bar .bar .bar-content span{
    position: absolute;
    top: -20px;
    font-size: 15px;
    font-weight: 600;
}


.stats .text>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 150px;
    border-radius: 6px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    color: #fff;
}

.stats .images{
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    height: 600px;
}

.stats .images img{
    width: 45%;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
}

.stats .images img:nth-child(1){
    width: 50%;
    margin-top: -100px;
}

.stats .images img:nth-child(2){
    margin-bottom: -100px;
}

.stats .images a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #b6ab38;
    border-radius: 50%;
    font-size: 46px;
    color: #000;
    border: 2px solid #fff;
    color: #fff;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
}


@media(max-width:950px){
    .stats .text h1 {
        font-size: 32px;
        font-weight: 800;
        color: #000;
    }
}