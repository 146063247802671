footer{
    background-color: #000;
    padding-top: 48px;
    padding-bottom: 10px;
}


footer .footer-content .logo img{
    width: 141px;
}

footer .footer-content p{
    font-size: 16px;
    color: #fff;
    font-weight: 200;
}

footer .footer-content .input{
    width: 100%;
    position: relative;
}


footer .footer-content .input input{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: #ffffff0f;
    outline: none;
    border: none;
    padding-left: 15px;
}

footer .footer-content .input button{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: transparent;
    outline: none;
    border: none;
    color: #fff;
    font-size: 22px;
}

footer .footer-content h2{
    font-size: 13px;
    color: #747474;
    margin-top: 13px;
}

footer .footer-content h1{
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    font-weight: 400;
    color: #baa63f;
}

footer .footer-content ul{
    padding: 0;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    color: #fff;
    margin-top: 30px !important;
}


footer .footer-content ul li a{
    color: #fff;
}



footer .copy-right{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #ffffff2e;
}


footer .copy-right h5{
    color: #fff;
    font-size: 13px;
    font-weight: 300;
}

footer .copy-right .socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

footer .copy-right .socials a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
}
