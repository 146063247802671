

.why-choose-us .reason{
    margin-bottom: 50px;
}

.why-choose-us .reason .top{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
}

.why-choose-us .reason .top i{
    font-size: 60px;
    color: #b6ab38;
}

.why-choose-us .reason .top div{
    height: 60px;
}

.why-choose-us .reason .top div h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
    margin: 0;
}

.why-choose-us .reason .top div h1{
    font-size: 40px;
    font-weight: 600;
    color: #000;
}

.why-choose-us .reason p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}



.why-choose-us .text h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
}

.why-choose-us .text h1{
    font-size: 60px;
    font-weight: 800;
    color: #000;
}

.why-choose-us .text h1 span{
    color: #b6ab38;
}

.why-choose-us .text p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}

.why-choose-us .text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: #000;
    font-size: 16px;
}



@media(max-width:950px){
    .why-choose-us .text h1 {
        font-size: 39px;
        font-weight: 800;
        color: #000;
    }

    .why-choose-us .reason {
        margin-bottom: 50px;
        margin-top: 23px;
    }
}