.faqs{
    margin-top: 60px;
    margin-bottom: 120px;
}


.faqs .faqs-content .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 120px;
}

.faqs .faqs-content .header h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
}

.faqs .faqs-content .header h1{
    font-size: 60px;
    font-weight: 800;
    color: #000;
}

.faqs .faqs-content .header h1 span{
    color: #b6ab38;
}

.faqs .faqs-content .header p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
    text-align: center;
}

.faqs .faqs-content .questions{
    padding: 50px;
    padding-bottom: 0;
}

.faqs .faqs-content .questions .question{
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin-bottom: 32px;
}

.faqs .faqs-content .questions .question h1{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: 21px;
    font-weight: 700;
    color: #000;
    height: 40px;
    align-items: center;
    cursor: pointer;
}




.faqs .faqs-content .questions .question p{
    width: 66%;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    display: none;

}

.faqs .faqs-content .questions .question p.active{
    display: block;
}


.faqs .faqs-content .more-questions{
    margin: 50px;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    padding: 19px;
    border-radius: 6px;
}


.faqs .faqs-content .more-questions h1{
    width: 39%;
    font-size: 23px;
    font-weight: 500;
    color: #000;
    color: #fff;
}


.faqs .faqs-content .more-questions a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 45px;
    background-color: #fff;
    border-radius: 6px;
    color: #b5a942;
}



@media(max-width:950px){
    .faqs .faqs-content .header h1 {
        font-size: 45px;
        font-weight: 800;
        color: #000;
    }

    .faqs .faqs-content .questions {
        padding: 0;
        padding-bottom: 0;
    }

    .faqs .faqs-content .more-questions {
        margin: 0;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 158px;
        background: rgb(185, 173, 68);
        background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
        padding: 19px;
        border-radius: 6px;
    }

    .faqs .faqs-content .more-questions h1 {
        width: 100%;
        font-size: 23px;
        font-weight: 500;
        color: #000;
        color: #fff;
    }
}