

.our-services .section-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 50px;
}


.our-services .section-header h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
}

.our-services .section-header h1{
    font-size: 60px;
    font-weight: 800;
    color: #000;
}


.our-services .section-header h1 span{
    color: #b6ab38;
}



.our-services .service{
    margin-bottom: 25px;
    position: relative;
}


.our-services .service i{
    font-size: 75px;
    color: #b6ab38;
}


.our-services .service h1{
    font-size: 24px;
    font-weight: 600;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.our-services .service>span{
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 90px;
    font-weight: bolder;
    z-index: -1;
    opacity: 0.1;
}

.our-services .service h1 span{
    height: 30px;
    display: flex;
    width: 3px;
    background-color: #b6ab38;
}


.our-services .service p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}


.our-services .service a{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}


@media(max-width:950px){
    .our-services .section-header h1 {
        font-size: 36px;
        font-weight: 800;
        color: #000;
    }
    
}