.landing{
    margin-top: 60px;
}



.landing .text h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
}

.landing .text h1{
    font-size: 60px;
    font-weight: 800;
    color: #000;
}

.landing .text h1 span{
    color: #b6ab38;
}

.landing .text p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}

.landing .text div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.landing .text div a{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 187px;
    border-radius: 6px;
    color: #fff;
    background-color: #000;
    gap: 15px;
}

.landing .text div a:nth-child(1){
    width: 150px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    color: #fff;
}

.landing .images{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 18px;
}


.landing .images>div{
    position: relative;
    width: 50%;
    overflow: hidden;
    height: 550px;
    border-radius: 6px;
}

.landing .images>div:nth-child(1){
    height: 312px;
}


.landing .images>div img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 6px;
}


.landing .images>div .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #00000061;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3170518207282913) 0%, rgba(0, 0, 0, 1) 100%);
}


.landing .images>div a{
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: #fff;
    font-size: 16px;
}

.landing .images>div a i{
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #fff;
    border-radius: 50%;
}

.landing .image{
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 300px;
    border-radius: 6px;
    margin-top: 16px;
}

.landing .image a{
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: #fff;
    font-size: 16px;
}

.landing .image a i{
    
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #fff;
    border-radius: 50%;

}

.landing .image .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #00000061;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3170518207282913) 0%, rgba(0, 0, 0, 1) 100%);
}

.landing .image img{
    
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 6px;
}


@media(max-width:950px){
    .landing .text h1 {
        font-size: 39px;
        font-weight: 800;
        color: #000;
    }

    .landing .images {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 18px;
        margin-top: 30px;
    }

    .landing .images>div {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 550px;
        border-radius: 6px;
    }


    .landing .images {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 18px;
        margin-top: 30px;
    }
}