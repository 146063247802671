.about{
    margin-top: 80px;
    margin-bottom: 80px;
}

.about .image{
    position: relative;
    height: 721px;
    border-radius: 15px;
    overflow: hidden;
}

.about .image .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #00000061;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3170518207282913) 0%, rgba(0, 0, 0, 1) 100%);
}

.about .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px
}

.about .text h2{
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2a33f;
}

.about .text h1{
    font-size: 60px;
    font-weight: 800;
    color: #000;
}

.about .text h1 span{
    color: #b6ab38;
}


.about .text p{
    margin-top: 18px;
    font-size: 16px;
    color: #000;
    width: 90%;
    font-weight: 500;
}

.about .text .about-benefits{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}

.about .text .about-benefits .about-benefit{
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}


.about .text .about-benefits .about-benefit i{
    width: 60px;
    height: 60px;
    border-radius: 15px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #fff;
}


.about .text .about-benefits .about-benefit div{
    width: 80%;
}

.about .text .about-benefits .about-benefit div h3{
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.about .text .about-benefits .about-benefit div p{
    margin-top: 0;
    font-size: 14px;
    color: #5f5f5f;
}

.about .text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 150px;
    border-radius: 6px;
    background: rgb(185, 173, 68);
    background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
    color: #fff;
}


@media(max-width:950px){
    .about .text h1 {
        font-size: 39px;
        font-weight: 800;
        color: #000;
    }

    .about .text .about-benefits .about-benefit {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }

    .about .text .about-benefits {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .about .text a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 150px;
        border-radius: 6px;
        background: rgb(185, 173, 68);
        background: linear-gradient(232deg, rgba(185, 173, 68, 1) 0%, rgba(109, 94, 32, 1) 100%);
        color: #fff;
        margin-bottom: 50px;
    }

    .about .image {
        position: relative;
        height: 500px;
        border-radius: 15px;
        overflow: hidden;
    }

    
}